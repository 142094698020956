var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "signinBtn",
      staticClass: "g-sign-in-button",
      on: {
        click: function ($event) {
          return _vm.$emit("google-button-clicked")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }