var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "property-apps" },
    _vm._l(_vm.property_apps, function (module, key) {
      return _c(
        "el-card",
        {
          key: key,
          staticClass: "app-item",
          attrs: { shadow: "always", "body-style": { padding: "0" } },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix title",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("img", { attrs: { src: module.icon, alt: "" } }),
              _c("span", { staticStyle: { "font-weight": "700" } }, [
                _vm._v(_vm._s(_vm.$t(module.module_name))),
              ]),
            ]
          ),
          _vm._l(module.children, function (module_item, ch_key) {
            return _c(
              "el-card",
              {
                key: ch_key,
                staticClass: "card-item",
                staticStyle: { "border-radius": "0" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goToModuleItem(module_item)
                  },
                },
              },
              [
                _c("span", { staticClass: "property-name" }, [
                  _vm._v(_vm._s(_vm.$t(module_item.name))),
                ]),
              ]
            )
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }