import axios from 'axios'
import * as Security from '@/common/security_manager'
import {hasTokenExpired, hkTokenOrBearerToken, hkTokenOrBearerTokenCognito, logout} from '@/common/utils'
import store from '@/store/store'
import mutations from '@/store/mutation_types'
import {setCookies, getCookie} from '@/common/utils'
import dayjs from "dayjs";
import {getGoogleToken} from "@/common/security_manager";

export default {
  login (username, password) {
    let token = Security.getToken(username, password)

    const apiClient = axios.create({
      baseURL: `${hkConfigEnvs.VUE_APP_LOGIN_API}`,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-token': token.token,
        'hk-info': token.info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.post('/').then((res)=> {

      try {
        store.commit(mutations.SET_TOKEN_EXPIRY_DATE, res.data.token_expiry);
      } catch (e) {
        console.error(e);
      }

      return res;
    })
  },

  logout() {
    const tokenObj = Security.getTokenObject();

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'hk-info': tokenObj.info,
      'hk-app-id': 'HK_HOME',
      'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
      'hk-session-id': getCookie('hk_session_id')
    };

    if (tokenObj && tokenObj.hasOwnProperty('Authorization')) {
      headers['Authorization'] = tokenObj.Authorization
    } else {
      headers['hk-token'] = tokenObj.token
    }

    const apiClient = axios.create({
      baseURL: `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signout`,
      //withCredentials: false, // this is the default
      headers
    });

    const params = {}
    if (getCookie('signout_from_all_devices')) params.signout_from_all_devices = true

    return apiClient.post("/", {}, { params });
  },

  verifyToken(userId, payload, username, password) {
    let token = Security.getToken(username, password)

    const apiClient = axios.create({
      baseURL: `${hkConfigEnvs.VUE_APP_BASE_URL_API}/${hkConfigEnvs.VUE_APP_CORE_API}/account/users/${userId}/validate-2factor-code`,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-token': token.token,
        'hk-info': token.info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.post('', payload)
  },
  refreshToken() {
    let tokenObj = Security.getTokenObject();

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'hk-info': tokenObj.info,
      'hk-app-id': 'HK_HOME',
      'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
      'hk-session-id': getCookie('hk_session_id')
    };

    if (tokenObj && tokenObj.hasOwnProperty('Authorization')) {
      headers['Authorization'] = tokenObj.Authorization
    } else {
      headers['hk-token'] = tokenObj.token
    }

    const region_url = store.state.current?.property?.region?.url?.slice(0, -1);

    const apiClient = axios.create({
      baseURL: `${region_url || hkConfigEnvs.VUE_APP_BASE_URL_API}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/sso/refresh`,
      withCredentials: false, // this is the default
      headers
    });

    return apiClient.post('')
  },

  is5MinutesLeftBeforeTokenExpire(token_expiry_date){

    const expiry_date = dayjs(token_expiry_date)
    const now = dayjs(new Date().getTime())
    let diff = expiry_date.diff(now,'minute')

    console.log("diff",diff);

    return diff < 5;
  },

  guardTriggerOnInvalidCases() {
    if (localStorage.getItem('hk_token')?.startsWith('GOOGLE')) return true;
    if (localStorage.getItem('hk_token_expiry') == null || localStorage.getItem('hk_token_expiry') == undefined) return true;

    let hk_token_expiry = localStorage.getItem('hk_token_expiry');

    if (hk_token_expiry && hasTokenExpired(hk_token_expiry)) return true;


    return false;
  },

  initializeRefreshTokenTrigger() {
   if (this.guardTriggerOnInvalidCases()) return;

   if (store?.state?.triggerIntervalId) {
     try {
       clearInterval(store?.state?.triggerIntervalId)
     } catch (e) { console.error(e)}
   }

   if (localStorage.getItem('hk_token') == null) return;
   if (store?.state?.current?.user == null) return;

    let intervalId = setInterval(()=>{

      if (this.guardTriggerOnInvalidCases()) return;

      console.log("Checking Refresh Token");
      if (this.is5MinutesLeftBeforeTokenExpire(localStorage.getItem('hk_token_expiry'))) {
        console.log("is55MinutesLeftBeforeTokenExpire",localStorage.getItem('hk_token_expiry'))

        this.refreshToken().then((resp)=>{
          console.log("token refreshed: ", resp.data);

            store.commit(mutations.SET_HK_TOKEN,`Bearer ${resp.data.token}`)
            store.commit(mutations.SET_TOKEN_EXPIRY_DATE,resp.data.token_expiry)

            setCookies(false);
        }).catch(error=>{
          if (error.response?.status === 403) {
            logout();
          }
        })
      }
    },60000);

    store.commit(mutations.SET_TRIGGER_INTERVAL_ID,intervalId);

  },
  loginWithSSO (payload) {
    const ssoLoginUrl = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/sso-login`
    const apiClient = axios.create({
      baseURL: ssoLoginUrl,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return apiClient.post('', payload).then((res)=> {

      try {
        store.commit(mutations.SET_TOKEN_EXPIRY_DATE, res.data.token_expiry);
      } catch (e) {
        console.error(e);
      }

      return res;
    })
  },
  loginWithCognito(payload,pool_id,app_id) {

    let cognitoLoginUrl = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/sso-login/cognito/${pool_id}`
    let apiClient = axios.create({
      baseURL: cognitoLoginUrl,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (app_id) {
      cognitoLoginUrl = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/sso-login/cognito/${pool_id}/app/${app_id}`
      apiClient = axios.create({
        baseURL: cognitoLoginUrl,
        withCredentials: false, // this is the default
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }


    return apiClient.post('', payload).then((res)=> {

      try {
        store.commit(mutations.SET_TOKEN_EXPIRY_DATE, res.data.token_expiry);
      } catch (e) {
        console.error(e);
      }

      return res;
    })
  },
  //headers['Authorization'] = tokenObj.Authorization
  enrollTfa(token, info) {
    const cognitoLoginUrl = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/cognito/enroll-tfa`
    const apiClient = axios.create({
      baseURL: cognitoLoginUrl,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-info': info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        ...hkTokenOrBearerTokenCognito(token),
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.post('', {})
  },
  enrollTfaConfirm(token, info, payload) {
    const cognitoLoginUrl = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/cognito/enroll-tfa/confirm`
    const apiClient = axios.create({
      baseURL: cognitoLoginUrl,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-info': info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        ...hkTokenOrBearerTokenCognito(token),
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.post('', payload)
  },

  getProperties (token, info) {
    const apiClient = axios.create({
      baseURL: `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/${hkConfigEnvs.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/mini-objects`,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-info': info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        ...hkTokenOrBearerToken(token),
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.get('/')
  },

  getPropertiesCognito(token, info) {
    const apiClient = axios.create({
      baseURL: `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/${hkConfigEnvs.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/mini-objects`,
      withCredentials: false, // this is the default
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hk-info': info,
        'hk-app-id': 'HK_HOME',
        'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
        ...hkTokenOrBearerTokenCognito(token),
        'hk-session-id': getCookie('hk_session_id')
      }
    })

    return apiClient.get('/')
  }
}
