<template>
  <div class="wrapper login" v-loading="loading">
    <el-card shadow="always" class="card" v-loading="loadingGoogleSignIn || loadingSSO"
             :body-style="{ 'text-align': 'center' }">
      <img :src="logo_url" alt="Hk-Logo">
      <div v-if="!showChangePassword">
        <div v-if="disable_manual_login === 'false'">
          <el-form :model="credentials" status-icon :rules="rules" ref="login" label-width="120px"
                   class="demo-ruleForm">
            <el-form-item label="" prop="username">
              <el-input v-model="credentials.username" class="input" type="text"
                    @keydown.enter.prevent.native="userNameOnKeypress($event)"  placeholder="Username"
                    :disabled="show_pass"></el-input>
            </el-form-item>
            <div class="flexed-end fs-14"  v-if="show_pass">
              <a href="#" @click.prevent="changeUsername">Change Username</a>
              </div>
            <el-form-item label="" prop="password" v-if="show_pass">
              <el-input  v-model="credentials.password" class="input" type="password" placeholder="Password"></el-input>
            </el-form-item>
            <div v-if="recaptcha_show === 'true'">
              <vue-recap name="recaptcha" ref="recaptcha" @verify="onVerify" :sitekey="site_key"></vue-recap>
            </div>
            <el-button :loading="loading" @click="validateAndLogin()" class="button" type="primary">{{show_pass ? 'Login' : 'Next'}}</el-button>
            <div class="or" v-if="SSO_in_use">
              <hr>
              <div>OR</div>
            </div>
          </el-form>
        </div>
        <template v-if="SSO_in_use">
          <el-button :loading="loadingSSO" @click="loginWithSSO()" class="button" type="primary">Login with SSO
          </el-button>
        </template>
        <el-alert v-if="loginError"
                  :title="loginError.message || loginError"
                  type="error"
                  :closable="false"
                  show-icon>
        </el-alert>
      </div>
      <change-password v-if="showChangePassword"
                        :password-user-id="new_password_user_id"
                        :error-token="error_token"
                        :error-info="error_info"
                        @close="showChangePassword = false"/>
    </el-card>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import actions from '@/store/action_types'
import mutations from '@/store/mutation_types'
import {getToken} from '@/common/security_manager'
import GSignInButton from '@/components/GSignInButton'
import VueRecap from 'vue-recaptcha'
import axios from 'axios'
import {generateUUID, setCookies, am_I_atLocalhost, getCookie} from '@/common/utils'
import * as CryptoJS from 'crypto-js'
import base64url from 'base64url'
import ChangePassword from '@/views/login/ChangePassword'
import Global from '@/common/global.service';

export default {
  name: 'DifferentLogin',
  components: {
    GSignInButton,
    vueRecap: VueRecap,
    ChangePassword
  },
  mounted() {
    if (this.user) {
      this.$router.push('home')
    }
    else if(this.$route.query?.code){
      this.validateAndLogin(this.$route.query.code)
    }
  },
  data() {
    return {
      logo_url: hkConfigEnvs.VUE_APP_LOGO,
      disable_manual_login: hkConfigEnvs.VUE_APP_DISABLE_MANUAL_LOGIN,
      loadingGoogleSignIn: false,
      loading: false,
      loadingSSO: false,
      credentials: {
        username: null,
        password: null,
      },

      loginError: null,
      googleSignInParams: {
        client_id: hkConfigEnvs.VUE_APP_GOOGLE_CLIENT_ID,
        hosted_domain: 'hotelkeyapp.com'
      },
      recaptcha_status: null,
      site_key: hkConfigEnvs.VUE_APP_RECAPTCHA_SITE_KEY,
      google_sign_in: hkConfigEnvs.VUE_APP_GOOGLE_LOGIN_STATUS,
      SSO_in_use: hkConfigEnvs.VUE_APP_OAUTH_AUTH_URL === 'true',
      recaptcha_show: hkConfigEnvs.VUE_APP_RECAPTCHA_STATUS,
      showChangePassword: false,
      new_password_user_id: null,
      error_token: null,
      error_info: null,
      show_pass: false,

      rules: {
        username: [
          {required: true, message: 'Field is required', trigger: 'change'},
        ],
        password: [
          {required: true, message: 'Field is required', trigger: 'change'},
        ],

      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.current.user,
      property: state => state.current.property,
      hk_info: state => state.current.hk_info,
      google_login: state => state.current.google_login
    })
  },
  methods: {
    ...mapActions([
      actions.login
    ]),
    changeUsername(){
      this.loading = true
      window.location.reload()
    },
    getSessionId(){
      const random72CharString = generateUUID() + generateUUID()
      localStorage.setItem('CodeVerifier', random72CharString)
      const sha266OfRandom = CryptoJS.SHA256(random72CharString)
      const buffer = Buffer.from(sha266OfRandom.toString(CryptoJS.enc.Hex), 'hex')
      let arrayOfBuffer = new Uint8Array(buffer)
      let sessionId = base64url.encode(arrayOfBuffer)
      sessionId = encodeURIComponent(sessionId.replace('=', ''))
      if(am_I_atLocalhost())document.cookie = `hk_session_id=${sessionId}`
      else localStorage.setItem('hk_session_id',sessionId);
      return sessionId
    },
    ...mapMutations([
      mutations.SET_CURRENT_USER,
      mutations.SET_HK_TOKEN,
      mutations.SET_HK_INFO,
      mutations.SET_CURRENT_USER_PROPERTIES,
      mutations.SET_GOOGLE_LOGIN,
      mutations.SET_TOKEN_EXPIRY_DATE,
      mutations.SET_ENTERPRISE_PERMISSIONS,
      mutations.SET_PROPERTIES_FROM_USER
    ]),
    userNameOnKeypress(event){
      if(event && event.keyCode === 13  || event.code == 'Enter') this.validateAndLogin()
    },
    validateAndLogin(codeFromQueryParam) {
      if(codeFromQueryParam) this.login(codeFromQueryParam)
      else {
        this.$refs['login'].validate((valid) => {
        if (valid) {
          this.login(codeFromQueryParam)
        } else {
          return false;
        }
      });
      }
    },
    login(codeFromQueryParam){
      this.loading = true
          let sessionId = this.getSessionId()
          let params = {
            // username: this.credentials.username,
            session_id: sessionId,
            state: 'HK_HOME'
          }
          if(codeFromQueryParam) params.code = codeFromQueryParam
          else params.username = this.credentials.username

          // console.log("LOCAL S",localStorage.getItem('CodeVerifier'))
          // setTimeout(() => {
          //   console.log("aaa")


          if (this.show_pass) {
            this.loginRegular();

          } else {
            Global.getCognito(params).then(res => {
                this.loading = true
                window.location.href = res.data.redirect_url
            }).catch(error => {
              if (error.response?.status === 302) {
              } else if (error.response?.status === 404 || error.response?.status === 400) {
                this.show_pass = true;
                this.loading = false
              } else if (error.response?.status >= 500) {
                this.$notify({
                  title: 'Error',
                  message: 'Not able to validate',
                  type: 'error'
                })
              } else if (error.response?.status === undefined && error.config.url === 'https://enterprise.hkdev0.hotelkeyapp.com/v4/account/signin/init/cognito') {
                this.loading = true
                let  urlToRedirect = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/init/cognito?`
                urlToRedirect = codeFromQueryParam ? urlToRedirect + `username=${this.credentials.username}&session_id=${sessionId}&state=HK_HOME` :
                                      urlToRedirect + `code=${codeFromQueryParam}&session_id=${sessionId}&state=HK_HOME`
                window.location.href = urlToRedirect
              } else {
                this.show_pass = true;
                this.loading = false
              }

            })
          }
    },
    loginRegular() {
      this.loading = true

      if ((this.recaptcha_status === null || this.recaptcha_status === false) && this.recaptcha_show === 'true') {
        this.$notify({
          title: 'Warning',
          message: 'Please solve the recaptcha first',
          type: 'warning'
        })
        this.loading = false
      } else {
        this[actions.login]({username: this.credentials.username, password: this.credentials.password}).then(() => {
          this[mutations.SET_GOOGLE_LOGIN](false)
          this.$router.push({name: 'home'})
        }).catch((error) => {
          this.handleLoginError(error)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    // login () {
    //
    //   // https://00.us-east-1.hkdev0.hotelkeyapp.com/v4/account/signin/init/cognito?username=amir.idrizi@hotelkeyapp.com&session_id=RRkR7thBgrjGNcYo8qlVqeo9GK_lhRFlk0UYvySNvy4&state=hk-admin
    //
    //   const random72CharString = generateUUID() + generateUUID()
    //   localStorage.setItem('CodeVerifier', random72CharString)
    //   const sha266OfRandom = CryptoJS.SHA256(random72CharString)
    //   const buffer = Buffer.from(sha266OfRandom.toString(CryptoJS.enc.Hex), 'hex')
    //   let arrayOfBuffer = new Uint8Array(buffer)
    //   let sessionId = base64url.encode(arrayOfBuffer)
    //   sessionId = encodeURIComponent(sessionId.replace('=', ''))
    //
    //   const urlToRedirect = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/init/cognito?username=${this.username}@hotelkeyapp.com&session_id=${sessionId}&state=hk-admin`
    //   window.location.href = urlToRedirect


    // this.loading = true
    //
    // if ((this.recaptcha_status === null || this.recaptcha_status === false) && this.recaptcha_show === 'true') {
    //   this.$notify({
    //     title: 'Warning',
    //     message: 'Please solve the recaptcha first',
    //     type: 'warning'
    //   })
    //   this.loading = false
    // } else {
    //   this[actions.login]({ username: this.username, password: this.password }).then(() => {
    //     this[mutations.SET_GOOGLE_LOGIN](false)
    //     this.$router.push({ name: 'home' })
    //   }).catch((error) => {
    //     this.handleLoginError(error)
    //   }).finally(() => {
    //     this.loading = false
    //   })
    // }
    // },

    loginWithSSO() {
      this.loadingSSO = true
      try {
        let sessionId = this.getSessionId()
        const urlToRedirect = `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signin/init?session_id=${sessionId}`
        window.location.href = urlToRedirect
      } catch (error) {
        console.log('error', error)
        this.loadingSSO = false
        this.$notify.error({
          title: 'Error',
          message: 'Please try again'
        })
      }
    },
    onSignInSuccess(googleUser) {
      // See https://developers.google.com/identity/sign-in/web/reference#users
      let profile = googleUser.getBasicProfile().getEmail()
      let idToken = googleUser.getAuthResponse().id_token
      let expiresAt = googleUser.getAuthResponse().expires_at

      this[mutations.SET_GOOGLE_LOGIN](true)

      this[mutations.SET_HK_TOKEN](`GOOGLE-${idToken}`)
      this[mutations.SET_TOKEN_EXPIRY_DATE](expiresAt)

      let token = getToken(profile, idToken)

      this[mutations.SET_HK_INFO](token.info)

      const signInClient = axios.create({
        baseURL: `${hkConfigEnvs.VUE_APP_LOGIN_API}`,
        withCredentials: false,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'hk-token': localStorage.getItem('hk_token'),
          'hk-info': this.hk_info,
          'hk-app-id': 'HK_HOME',
          'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
          'hk-session-id': getCookie('hk_session_id')
        }
      })

      const apiClient = axios.create({
        baseURL: `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/${hkConfigEnvs.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/mini-objects`,
        withCredentials: false,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'hk-token': localStorage.getItem('hk_token'),
          'hk-info': this.hk_info,
          'hk-app-id': 'HK_HOME',
          'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
          'hk-session-id': getCookie('hk_session_id')
        }
      })

      return signInClient.post('/').then((response) => {
        return apiClient.get('/', {cache: false}).then(res => {
          this.loadingGoogleSignIn = false
          this[mutations.SET_CURRENT_USER](response.data.user)
          this[mutations.SET_ENTERPRISE_PERMISSIONS](response.data.enterprise_permissions)
          this[mutations.SET_CURRENT_USER_PROPERTIES](res.data)
          this[mutations.SET_PROPERTIES_FROM_USER](response.data.properties)
          setCookies(false)
          this.$router.push('home')
        })
      }).catch((error) => {
        if (error.response && error.response.status === 423) {
          this.loginError = error.response.data
        } else if (error.response.status === 424) {
          this.loginError = error.response.data
        } else {
          this.loginError = error
        }
      }).finally(() => {
        this.loadingGoogleSignIn = false
        this.loading = false
      })
    },
    onSignInError(error) {
      this.loginError = 'Couldn\'t sign in with HotelKey Account'
      console.log('Google sign in Error', error)
      this.loading = false
    },
    onVerify(res) {
      axios.post(`${hkConfigEnvs.VUE_APP_BASE_URL_API}/${hkConfigEnvs.VUE_APP_CORE_API}/check-captcha?g_recaptcha=${res}`)
        .then((res) => {
          if (res.data.status === false) {
            this.recaptcha_status = false
            this.$refs('recaptcha').recaptcha.reset()
            this.showAlert = true
            setTimeout(() => {
              if (this.showAlert === true) {
                this.showAlert = false
              }
            }, 5300)
          } else {
            this.recaptcha_status = true
          }
        }).catch(err => {
        this.$log.debug(err)
        this.$refs.recaptcha.reset()
      })
    },
    handleLoginError(error) {
      switch (error.response.status) {
        case 401: {
          this.loginError = 'User password is incorrect'
          break
        }
        case 400: {
          this.loginError = 'Invalid Request'
          break
        }
        case 404: {
          this.loginError = 'Resource not found'
          break
        }
        case 426: {
          let token = error.response.config.headers
          this.new_password_user_id = error.response.data.user_id
          this.error_token = token['hk-token']
          this.error_info = token['hk-info']
          this.showChangePassword = true
          break
        }
        case 303: {
          this.$router.push({
            name: 'qr-code',
            params: {
              ...error.response.data,
              username: this.credentials.username,
              password: this.credentials.password,
              firstTime: true
            }
          })
          break
        }
        case 450: {
          this.$router.push({
            name: 'qr-code',
            params: {
              ...error.response.data,
              username: this.credentials.username,
              password: this.credentials.password,
              firstTime: false
            }
          })
          break
        }
        case 451: {
          this.$router.push({
            name: 'qr-code',
            params: {
              ...error.response.data,
              username: this.credentials.username,
              password: this.credentials.password,
              firstTime: false
            }
          })
          break
        }
        case 500:
        case 502:
        case 503: {
          this.loginError = 'Server Error'
          break
        }
        default: {
          this.loginError = error.response.data
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
$blue: #46A0FC;

a{
  text-decoration: unset;
}
.login{
  :deep(.el-form-item__content) {
    margin-left: 0px !important;
  }

  .el-form{
    .el-form-item{
      margin-bottom: 10px;
    }
  }
}

.card {
  max-width: 380px;
  margin: 60px auto 0 auto;

  img {
    /*width: 96px;*/
    width: fit-content;
    height: 96px;
    margin-bottom: 10px;
  }

  .input {
    margin: 10px 0 auto;
  }

  .button {
    width: 100%;
    margin: 10px 0;
  }

  .or {
    position: relative;
    margin-top: 20px;

    hr {
      margin-top: 21px;
      margin-bottom: 21px;
      border: 0;
      border-top: 1px solid #eee;
    }

    div {
      font-size: 12px;
      position: absolute;
      font-weight: 400;
      left: 42%;
      padding: 0 20px;
      background: #fff;
      top: -8px;
    }
  }

  .g-sign-in-button {
    display: flex;
    align-items: center;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #fff;
    color: $blue !important;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 2px 6px #ccc;
  }
}
</style>
