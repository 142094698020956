<template>
  <div>
    <h4>Password must be changed</h4>
    <el-input v-model="new_password" class="input" clearable
              type="password" placeholder="New password"/>

    <el-input v-model="confirm_password" class="input" clearable
              type="password" placeholder="Confirm new Password"/>

    <el-button :loading="loading" @click="changePassword"
               class="button" type="primary" style="width: 100%; margin: 10px 0">Change Password</el-button>

    <el-alert v-if="login_error"
              :title="login_error"
              type="error"
              :closable="false"
              show-icon>
    </el-alert>
  </div>
</template>

<script>
import axios from 'axios'
import { getCookie} from '@/common/utils'

export default {
  name: 'ChangePassword',
  props: ['passwordUserId', 'errorToken', 'errorInfo'],
  data() {
    return {
      new_password: null,
      confirm_password: null,
      error_change_password: false,
      login_error: null,
      loading: false
    }
  },
  methods: {
    changePassword () {
      if (this.new_password !== this.confirm_password) {
        this.login_error = 'Password and Confirmation Password need to be same'
      } else {
        this.loading = true

        let payload = {
          'new_password': this.new_password,
          'user_id': this.passwordUserId
        }

        let apiClient = axios.create({
          baseURL: `${hkConfigEnvs.VUE_APP_CHANGE_PASSWORD_API}`,
          withCredentials: false,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'hk-token': this.errorToken,
            'hk-info': this.errorInfo,
            'hk-app-id': 'HK_HOME',
            'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
            'hk-session-id': getCookie('hk_session_id')
          }
        })

        return apiClient.post('', payload).then(response => {
          this.loginError = null
          console.log('response',response);
          this.$notify({
            title: 'Success',
            message: 'Password successfully changed.',
            type: 'success'
          })
          this.$emit('close')
        }).catch(error => {
          this.$log.debug(error)

          this.login_error = error?.response?.data?.message
        }).finally(() => {
          this.loading = false
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .input {
    margin: 10px 0;
  }
</style>
