var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-header",
    [
      _c("div", { staticClass: "title" }, [
        _c("img", { attrs: { src: _vm.logo_url, alt: "" } }),
        _c("h3", [_vm._v("Home")]),
      ]),
      _vm.reservationCreatedFromBooking.show
        ? _c("el-alert", {
            staticClass: "confirmation-alert",
            attrs: {
              title: `Reservation Confirmation #${_vm.reservationCreatedFromBooking.confirmation_no} created`,
              type: "success",
              "show-icon": "",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "name",
          on: {
            click: function ($event) {
              _vm.show = !_vm.show
            },
            mouseleave: function ($event) {
              _vm.show = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-name" },
            [
              _c("user-icon", { staticClass: "user-icon" }),
              _c("span", [
                _vm._v(_vm._s(`${_vm.first_name} ${_vm.last_name}`)),
              ]),
              !_vm.show
                ? _c("chevron-down-icon", { staticClass: "caret-icon" })
                : _c("chevron-up-icon", { staticClass: "caret-icon" }),
            ],
            1
          ),
          _vm.show
            ? _c(
                "div",
                { staticClass: "logout", on: { click: _vm.logout } },
                [
                  _c("log-out-icon", { staticClass: "custom-class" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("btn_logout")))]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dropdown",
        { staticClass: "user-icon-mobile", attrs: { trigger: "click" } },
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link" },
            [
              _c("user-icon", {
                staticClass: "user-icon",
                staticStyle: { "padding-top": "3px" },
              }),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { disabled: "" } }, [
                _vm._v(_vm._s(`${_vm.first_name} ${_vm.last_name}`)),
              ]),
              _c(
                "el-dropdown-item",
                {
                  attrs: { divided: "", icon: "el-icon-arrow-left" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.logout.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("btn_logout")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }