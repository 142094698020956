var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "card", attrs: { shadow: "always" } }, [
        _c("div", { staticClass: "m-b-10" }, [
          _vm._v(
            " To set up Two Factor Authentication please follow below steps "
          ),
        ]),
        _c("div", { staticClass: "m-b-5" }, [
          _vm._v(
            " Download Google Authenticator or Microsoft Authenticator App using below links "
          ),
        ]),
        _c("div", { staticClass: "flexed-column m-b-10 gap-5" }, [
          _c("a", { attrs: { href: _vm.googleIosHref, target: "_blank" } }, [
            _vm._v("Download Google Authenticator for iOS"),
          ]),
          _c(
            "a",
            { attrs: { href: _vm.googleAndroidHref, target: "_blank" } },
            [_vm._v("Download Google Authenticator for Android")]
          ),
          _c("a", { attrs: { href: _vm.microsoftIosHref, target: "_blank" } }, [
            _vm._v("Download Microsoft Authenticator for iOS"),
          ]),
          _c(
            "a",
            { attrs: { href: _vm.microsoftAndroidHref, target: "_blank" } },
            [_vm._v("Download Microsoft Authenticator for Android")]
          ),
        ]),
        _c("div", { staticClass: "m-b-20" }, [
          _vm._v(
            " Once Installed, Open the app and tap on the (+) icon and scan the QR code below "
          ),
        ]),
        _c(
          "div",
          { staticClass: "qr-code m-b-20" },
          [
            _c("qrcode-vue", {
              attrs: {
                value: _vm.$route.params.qr_code_url,
                size: "150",
                level: "H",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flexed",
            attrs: { name: "verifyForm", role: "form", novalidate: "" },
          },
          [
            _c("el-input", {
              staticClass: "form-control",
              attrs: {
                name: "token",
                type: "text",
                id: "token",
                placeholder: "Enter your code here",
                required: "",
                autofocus: "",
              },
              nativeOn: {
                keypress: function ($event) {
                  return _vm.tokenOnKeypress($event)
                },
              },
              model: {
                value: _vm.token,
                callback: function ($$v) {
                  _vm.token = $$v
                },
                expression: "token",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "5px" },
                attrs: { loading: _vm.loading, type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.verify()
                  },
                },
              },
              [_vm._v("Verify")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }