var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Password must be changed")]),
      _c("el-input", {
        staticClass: "input",
        attrs: { clearable: "", type: "password", placeholder: "New password" },
        model: {
          value: _vm.new_password,
          callback: function ($$v) {
            _vm.new_password = $$v
          },
          expression: "new_password",
        },
      }),
      _c("el-input", {
        staticClass: "input",
        attrs: {
          clearable: "",
          type: "password",
          placeholder: "Confirm new Password",
        },
        model: {
          value: _vm.confirm_password,
          callback: function ($$v) {
            _vm.confirm_password = $$v
          },
          expression: "confirm_password",
        },
      }),
      _c(
        "el-button",
        {
          staticClass: "button",
          staticStyle: { width: "100%", margin: "10px 0" },
          attrs: { loading: _vm.loading, type: "primary" },
          on: { click: _vm.changePassword },
        },
        [_vm._v("Change Password")]
      ),
      _vm.login_error
        ? _c("el-alert", {
            attrs: {
              title: _vm.login_error,
              type: "error",
              closable: false,
              "show-icon": "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }