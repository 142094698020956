var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "hk-header",
        { on: { logUserOut: _vm.activateLogout } },
        [
          _c("list-icon", {
            attrs: { slot: "list-icon" },
            on: {
              click: function ($event) {
                _vm.show_properties = !_vm.show_properties
              },
            },
            slot: "list-icon",
          }),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c("property-list", {
            attrs: {
              "active-property": _vm.selected_property,
              "show-properties": _vm.show_properties,
            },
            on: {
              selectProperty: _vm.chooseProperty,
              clearProperty: _vm.deSelectProperty,
              closeProperties: function ($event) {
                _vm.show_properties = false
              },
            },
          }),
          _c("div", { staticClass: "content" }, [
            _vm.permissions_loading
              ? _c("div", { staticClass: "loading" }, [_c("loader-icon")], 1)
              : _c(
                  "div",
                  [
                    _vm.selected_property === null
                      ? _c("enterprise-apps")
                      : _c("property-apps", {
                          attrs: { property: _vm.selected_property },
                        }),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }