var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper login" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingGoogleSignIn || _vm.loadingSSO,
              expression: "loadingGoogleSignIn || loadingSSO",
            },
          ],
          staticClass: "card",
          attrs: { shadow: "always", "body-style": { "text-align": "center" } },
        },
        [
          _c("div", { staticClass: "img-wrapper" }, [
            _c("img", { attrs: { src: _vm.logo_url, alt: "Hk-Logo" } }),
          ]),
          !_vm.showChangePassword
            ? _c(
                "div",
                [
                  _vm.disable_manual_login === "false"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "login",
                              staticClass: "demo-ruleForm",
                              attrs: {
                                model: _vm.credentials,
                                "status-icon": "",
                                rules: _vm.rules,
                                "label-width": "120px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "username" } },
                                [
                                  _c("el-input", {
                                    staticClass: "input",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Username",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.credentials.username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "password" } },
                                [
                                  _c("el-input", {
                                    staticClass: "input",
                                    attrs: {
                                      type: "password",
                                      placeholder: "Password",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.login.apply(null, arguments)
                                      },
                                    },
                                    model: {
                                      value: _vm.credentials.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.recaptcha_show === "true"
                                ? _c(
                                    "div",
                                    [
                                      _c("vue-recap", {
                                        ref: "recaptcha",
                                        attrs: {
                                          name: "recaptcha",
                                          sitekey: _vm.site_key,
                                        },
                                        on: { verify: _vm.onVerify },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  staticClass: "button",
                                  attrs: {
                                    loading: _vm.loading,
                                    type: "primary",
                                  },
                                  on: { click: _vm.login },
                                },
                                [_vm._v("Login")]
                              ),
                              _vm.isDefaultPoolCode === "true"
                                ? _c("div", { staticClass: "or" }, [
                                    _c("hr"),
                                    _c("div", [_vm._v("OR")]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDefaultPoolCode
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "g-sign-in-button",
                            on: { click: _vm.loginWithCognito },
                          },
                          [_c("span", [_vm._v("Login with SSO")])]
                        ),
                      ]
                    : _vm._e(),
                  _vm.SSO_in_use && _vm.google_sign_in === "true"
                    ? _c(
                        "div",
                        {
                          staticClass: "or",
                          staticStyle: { "margin-top": "30px" },
                        },
                        [_c("hr"), _c("div", [_vm._v("OR")])]
                      )
                    : _vm._e(),
                  _vm.SSO_in_use
                    ? [
                        _c(
                          "el-button",
                          {
                            staticClass: "button",
                            attrs: { loading: _vm.loadingSSO, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.loginWithSSO()
                              },
                            },
                          },
                          [_vm._v("Login with SSO ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.loginError
                    ? _c("el-alert", {
                        attrs: {
                          title: _vm.loginError.message || _vm.loginError,
                          type: "error",
                          closable: false,
                          "show-icon": "",
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.showChangePassword
            ? _c("change-password", {
                attrs: {
                  "password-user-id": _vm.new_password_user_id,
                  "error-token": _vm.error_token,
                  "error-info": _vm.error_info,
                },
                on: {
                  close: function ($event) {
                    _vm.showChangePassword = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }