var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "properties",
      class: { "properties-mobile": _vm.showProperties },
    },
    [
      _c(
        "div",
        { staticClass: "properties-body" },
        [
          _vm.showProperties
            ? _c(
                "div",
                {
                  staticClass: "close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeProperties")
                    },
                  },
                },
                [_c("x-icon")],
                1
              )
            : _c(
                "div",
                { staticClass: "property-inputs" },
                [
                  !_vm.activeProperty
                    ? _c("el-input", {
                        attrs: {
                          tabindex: "1",
                          placeholder: "Search Property",
                          "prefix-icon": "el-icon-search",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.selectOnEnter.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      })
                    : _c("div", { staticClass: "active-property" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.activeProperty.code) +
                              " - " +
                              _vm._s(_vm.activeProperty.name)
                          ),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: { click: _vm.clearProperty },
                        }),
                      ]),
                ],
                1
              ),
          _c(
            "el-card",
            { attrs: { shadow: "always", "body-style": _vm.bodyStyle } },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "700", color: "#8a8988" } },
                    [_vm._v(_vm._s(_vm.$t("lbl_available_properties")))]
                  ),
                ]
              ),
              _c(
                "div",
                [
                  _c("recycle-scroller", {
                    staticClass: "scroller",
                    attrs: {
                      items: _vm.filteredProperties,
                      "item-size": 65,
                      "key-field": "id",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item, index }) {
                          return [
                            _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "property-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectProperty(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-card",
                                  {
                                    staticClass: "card-item",
                                    class: {
                                      "blue-color":
                                        _vm.activeProperty !== null &&
                                        _vm.activeProperty.id === item.id,
                                    },
                                    staticStyle: { "border-radius": "0" },
                                    attrs: {
                                      "body-style": {
                                        display: "flex",
                                        justifyContent: "space-between",
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "property-name" },
                                      [
                                        _vm._v(
                                          _vm._s(`${item.code} - ${item.name}`)
                                        ),
                                      ]
                                    ),
                                    _vm.activeProperty !== null &&
                                    _vm.activeProperty.id === item.id
                                      ? _c("i", {
                                          staticClass: "el-icon-check",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select" },
        [
          _c("h3", [_vm._v("Select Property")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                filterable: "",
                placeholder: "Search Property",
                clearable: "",
              },
              on: {
                change: function ($event) {
                  return _vm.selectPropertyMobile(_vm.propertyId)
                },
              },
              model: {
                value: _vm.propertyId,
                callback: function ($$v) {
                  _vm.propertyId = $$v
                },
                expression: "propertyId",
              },
            },
            _vm._l(_vm.properties, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: `${item.code} - ${item.name}`, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }